<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
        class="mr-3"
          color="primary"
          large
          :to="{
            name: 'PageModeratorInstitutionClassroomMigrate',
            params: { parentId: parent.id },
          }"
        >
          + Migrate
        </v-btn>
        <v-btn
          color="primary"
          large
          :to="{
            name: 'PageModeratorInstitutionClassroomAdd',
            params: { parentId: parent.id },
          }"
        >
          + {{ $t("action.new_classroom") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="all" value="all" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/institution/${parent.id}/classroom`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <!-- EOC -->
      <!-- BOC:[model] -->
      <v-tab-item key="trash" value="trash" class="px-1 py-2">
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/institution/${parent.id}/classroom/trashed`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
        ></BreadBrowseTable>
      </v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/institution";
import model from "@/models/items/classroom";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent"],
  data: () => ({
    //BOC:[tabs]
    tab: null,
    tabs: [
      {
        key: "all",
        label: "Inbox",
      },
      {
        key: "trash",
        label: "Trash",
      },
    ],
    //EOC
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>